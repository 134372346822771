import React from 'react';

import Icon from './Icon';

export default function FeedFallbackFavicon({
  className = '',
  text = 'FeedFavicon',
  width = '16',
  height = '16',
}: { className?: string; text?: string; width?: string; height?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" fill="#5C6977" fillOpacity="0.46" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.58338 3.33334C2.58338 2.91912 2.91916 2.58334 3.33338 2.58334C3.34793 2.58334 3.36248 2.58337 3.37703 2.58343C6.03316 2.59492 8.58582 3.65453 10.4657 5.53442C12.3559 7.42462 13.4167 9.99487 13.4167 12.6667C13.4167 13.0809 13.0809 13.4167 12.6667 13.4167C12.2525 13.4167 11.9167 13.0809 11.9167 12.6667C11.9167 12.6201 11.9163 12.5735 11.9156 12.527C11.8792 10.3043 10.9763 8.17858 9.40504 6.59508C7.7884 4.9909 5.60659 4.08334 3.33338 4.08334C2.91916 4.08334 2.58338 3.74755 2.58338 3.33334ZM2.58337 5.99902C2.58337 5.5848 2.91916 5.24902 3.33337 5.24902C3.35359 5.24902 3.37379 5.2491 3.39398 5.24927C5.3372 5.26515 7.20383 6.04448 8.5797 7.42035C9.96987 8.81052 10.751 10.7017 10.751 12.6667C10.751 13.0809 10.4152 13.4167 10.001 13.4167C9.58681 13.4167 9.25103 13.0809 9.25103 12.6667C9.25103 12.6243 9.25057 12.582 9.24967 12.5398C9.21686 11.0194 8.59546 9.56606 7.51906 8.48104C6.4039 7.37471 4.89969 6.74902 3.33337 6.74902C2.91916 6.74902 2.58337 6.41323 2.58337 5.99902ZM2.58349 8.6647C2.58349 8.25048 2.91928 7.9147 3.33349 7.9147C4.59376 7.9147 5.80242 8.41534 6.69356 9.30648C7.58471 10.1976 8.08535 11.4063 8.08535 12.6666C8.08535 13.0808 7.74956 13.4166 7.33535 13.4166C6.92113 13.4166 6.58535 13.0808 6.58535 12.6666C6.58535 11.8059 6.24149 10.9757 5.6329 10.3671C5.02431 9.75855 4.19417 9.4147 3.33349 9.4147C2.91928 9.4147 2.58349 9.07891 2.58349 8.6647Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
}
